import React, { useState, useEffect } from 'react';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<{ targetTime: number }> = ({ targetTime }) => {
  const calculateTimeLeft = () => {
    const difference = targetTime - Date.now();
    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (value: number): string => {
    return value.toString();
  };

  return (
    <div>
      <h2>Baby Name Countdown</h2>
      <h3>May 4th, 2024 @9pm EDT</h3>
      {Object.keys(timeLeft).map((interval) => (
        <span key={interval}>
          {formatTime(timeLeft[interval as keyof TimeLeft])} {interval}{' '}
        </span>
      ))}
    </div>
  );
};

export default CountdownTimer;
