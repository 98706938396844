import React from 'react';
import NestelMe from './logos/nestel.png'
import MomAndBaby from './photos/nannah-and-baby.png';
import Photo0 from './photos/photo0.png'
import MNFeeding from './photos/mn-feeding.png'
// import CountdownTimer from './components/CountdownTimer';
import './App.css';

// const targetDate = new Date("2024-05-04T20:00:00").getTime();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <iframe
          src="https://player.twitch.tv/?channel=moviepassmitch&parent=nestel.me&autoplay=true"
          height="400"
          width="600"
          title="Baby Naming"
          allowFullScreen>
        </iframe> */}
        <img src={NestelMe} className="App-logo" alt="logo" />
        {/* <CountdownTimer targetTime={targetDate} /> */}
        <a
          className="Baby-List"
          href="https://www.babylist.com/list/hannah-poisner"
          target="_blank"
          rel="noopener noreferrer"
        >
          Baby List
        </a>
      </header>

      <body className="App-body">
        <div className="row">
          <div className="column">
            <img src={MomAndBaby} alt="MomAndBaby" className="Photos" />
          </div>
          <div className="column">
            <img src={Photo0} alt="Photo0" className="Photos" />
          </div>
          <div className="column">
            <img src={MNFeeding} alt="MNFeeding" className="Photos" />
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;