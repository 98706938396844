import React from 'react';
import NestelMe from '../logos/nestel.png'
import CountdownTimer from '../components/CountdownTimer';
import '../App.css';

const targetDate = new Date("2024-04-26T00:00:00").getTime();

const Home = () => {
    return (
        <div className="App">
            <header className="App-header">
                <img src={NestelMe} className="App-logo" alt="logo" />
                <CountdownTimer targetTime={targetDate} />
                <a
                    className="Baby-List"
                    href="https://www.babylist.com/list/hannah-poisner"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Baby List
                </a>
            </header>
        </div>
    )
}

export default Home;